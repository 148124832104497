import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import { Route, Switch } from 'react-router-dom';
import AdminMain from './AdminMain';
import AccountRoot from '../Accounts';
import checkUser from '../AccountComponent';
import TokensComp from './Tokens';

class AdminComp extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Admin');
  }

  render() {
    const { match, account } = this.props;
    if (!account.data.master && !account.data.admin) {
      return <div>Unauthorized</div>;
    }
    return (
      <div className="container bg-white mt-3 py-3">
        <Switch>
          <Route exact path={match.path} component={AdminMain} />
          <Route path={`${match.path}/accounts`} component={AccountRoot} />
          <Route path={`${match.path}/tokens`} component={TokensComp} />
        </Switch>
      </div>
    );
  }
}

export default checkUser(AdminComp);

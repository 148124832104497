export const copyToClipboard = (str, next) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  next && next(true);
};

export const handleOnKeyDown = ({ event, onEnter, onEscape, sideEffect }) => {
  if (event.key === 'Enter' && onEnter) {
    if (typeof onEnter === 'function') {
      onEnter();
    }
    if (typeof sideEffect === 'function') {
      sideEffect();
    }
  } else if (event.key === 'Escape' && onEscape) {
    if (typeof onEscape === 'function') {
      onEscape();
    }
    if (typeof sideEffect === 'function') {
      sideEffect();
    }
  }
};

export const getReadableFileSizeString = function(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const trimIfNotNull = value => {
  if (value) return value.trim();
  return value;
};

export const debounce = (func, delay, immediate) => {
  let timeout;
  return function executedFunction() {
    let context = this;
    let args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
    if (callNow) func.apply(context, args);
  };
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import { Link } from 'react-router-dom';

class AdminMain extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Admin');
  }

  render() {
    const { match } = this.props;
    return (
      <div className="mt-3">
        <div className="row">
          <div className="col">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Amministrazione
                </li>
              </ol>
            </nav>
            <h1>Anagrafi</h1>
            <div className="list-group">
              <Link to={`${match.path}/tokens`} className="list-group-item list-group-item-action">
                Tokens
              </Link>
              <Link to={`${match.path}/accounts`} className="list-group-item list-group-item-action">
                Utenti
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminMain;

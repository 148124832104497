import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Token from '../../../lib/models/admin/Token';
import { PlusIcon, TrashcanIcon } from '@primer/octicons-react';
import { Link } from 'react-router-dom';

class TokenRow extends PureComponent {
  static propTypes = {
    token: PropTypes.object.isRequired,
    onDeleteToken: PropTypes.func.isRequired
  };

  render() {
    const { token } = this.props;
    const created_at = new Date(token.created_at);
    return (
      <tr>
        <td>{token.idpartial}</td>
        <td>{created_at.toLocaleString()}</td>
        <td>{token.created_by}</td>
        <td className="text-right">
          <button className="btn btn-sm btn-danger" onClick={this.deleteToken}>
            <TrashcanIcon />
          </button>
        </td>
      </tr>
    );
  }

  deleteToken = () => {
    const { token, onDeleteToken } = this.props;
    const x = window.confirm('Sicuro di voler cancellare il token?');
    if (x) {
      onDeleteToken(token.id);
    }
  };
}

class TokensComp extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  state = {
    tokens: false,
    token: null,
    error: null
  };

  render() {
    const { error, token, tokens } = this.state;
    if (tokens === false) {
      return <div className="entity-wrapper mt-3">Loading..</div>;
    }
    return (
      <div className="entity-wrapper mt-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Amministrazione</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Tokens
            </li>
          </ol>
        </nav>
        {token && (
          <div className="my-4 alert alert-primary">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.setState({ token: null }, this.loadTokens)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            Token:{' '}
            <strong>
              <pre>{token}</pre>
            </strong>
            <div className="small mb-3">Salva il token perchè non verrà più mostrato</div>
            <button className="btn btn-sm btn-primary" onClick={() => this.setState({ token: null }, this.loadTokens)}>
              CHIUDI
            </button>
          </div>
        )}
        {error && (
          <div className="my-4 alert alert-danger">
            Ops... {error}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.setState({ error: null })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Token</th>
              <th>Creato il</th>
              <th>Creato da</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {tokens.map((token, i) => {
              return <TokenRow key={i} token={token} onDeleteToken={this.onDeleteToken} />;
            })}
          </tbody>
        </table>
        <div className="mt-5">
          <button type="button" className="btn btn-primary" onClick={this.createToken} title="Crea nuovo token">
            <PlusIcon size="small" />
          </button>
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.loadTokens();
  }

  loadTokens = () => {
    Token.find()
      .then(tokens => this.setState({ tokens }))
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
  createToken = () => {
    Token.create()
      .then(token => {
        this.setState({ token });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
  onDeleteToken = id => {
    Token.delete(id)
      .then(() => {
        this.loadTokens();
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

export default TokensComp;

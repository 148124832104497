import { getData, postData, deleteData } from '../../utils/httputils';

class Account {
  static apiPath = '/api/v1/accounts';

  static list() {
    return getData(Account.apiPath);
  }

  static getMe() {
    return getData(Account.apiPath + '/me');
  }

  static doLogout() {
    return deleteData(Account.apiPath + '/login');
  }

  static doLogin(data) {
    return postData(Account.apiPath + '/login', data).then(json => {
      return json.sessionId;
    });
  }

  static doLostPassword(email) {
    return postData(Account.apiPath + '/lost-password', { email });
  }

  static getConfirmToken(token) {
    return postData(Account.apiPath + '/confirm', { token });
  }

  static checkConfirmToken(token) {
    return getData(Account.apiPath + '/confirm?token=' + token);
  }

  static doPasswordChange(old_password, password) {
    return postData(Account.apiPath + '/password', { old_password, password });
  }

  static postConfirmToken(token, password) {
    return postData(Account.apiPath + '/confirm/' + token, { password });
  }
}

export default Account;

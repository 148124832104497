import { getData } from '../utils/httputils';

const apiPath = '/api/v1/channels';

let channels;

export const loadChannels = async () => {
  if (!channels) {
    channels = await getData(apiPath);
  }
  return channels;
};

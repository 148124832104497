import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store';
import './assets/css/prynet.scss';

import App from './App';
import { setCurrentTheme } from './lib/utils/windowutils';

const initialState = {};
const store = configureStore(initialState);

setCurrentTheme();

ReactDOM.render(<App store={store} />, document.getElementById('root'));

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PlusIcon } from '@primer/octicons-react';
import Template from '../../lib/models/Template';
import { loadLangs } from '../../lib/models/Lang';
import Loading from '../Common/Loading';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';

const PLACE_HOLDERS = [
  {
    id: 'sender_name',
    desc: 'Nome mittente'
  },
  {
    id: 'sender_email',
    desc: 'Email mittente'
  },
  {
    id: 'recipient_name',
    desc: 'Nome destinatario'
  },
  {
    id: 'recipient_email',
    desc: 'Email destinatario'
  },
  {
    id: 'attachment_url',
    desc: 'URL allegato',
    is_html: true,
    enabled: template => {
      return template.use_attachment;
    }
  }
];
class TemplateTexts extends PureComponent {
  static propTypes = {
    template: PropTypes.object.isRequired
  };

  state = {
    error: false,
    current_tab: false,
    texts: false,
    langs: false,
    add_lang: false,
    lang: '',
    current_placeholder: false,
    current_placeholder_text: false
  };

  render() {
    const {
      error,
      texts,
      current_tab,
      add_lang,
      langs,
      lang,
      current_placeholder,
      current_placeholder_text
    } = this.state;
    if (texts === false) return <Loading />;
    const texts_langs = Object.keys(texts);
    const missing_langs = langs.filter(lang => !texts_langs.includes(lang));
    return (
      <div className="row">
        <div className="col col-lg-8">
          <div className="card border-primary">
            <div className="card-header bg-primary text-white">
              <strong>Testi</strong>
            </div>
            <div className="card-body">
              {error && <div className="alert alert-danger">{error}</div>}
              <ul className="nav nav-tabs">
                {texts_langs.map(lang => {
                  return (
                    <li className="nav-item" key={lang}>
                      <button
                        className={`btn btn-link nav-link ${current_tab === lang ? 'active' : ''}`}
                        onClick={e => {
                          e.target.blur();
                          this.setState({ current_tab: lang });
                        }}
                      >
                        {lang}
                      </button>
                    </li>
                  );
                })}
                <li className="nav-item">
                  <button
                    className="btn btn-link nav-link "
                    title="Aggiungi lingua"
                    onClick={this.addLang}
                    disabled={missing_langs.length === 0}
                  >
                    <PlusIcon />
                  </button>
                </li>
              </ul>
              <div className="mt-3">
                {!add_lang && texts_langs.length === 0 && <div className="alert alert-info">Nessun testo trovato</div>}
                {!add_lang && current_tab && (
                  <div className="row">
                    <div className="col">
                      <form onSubmit={this.onSubmit}>
                        <fieldset>
                          <legend>SMS</legend>
                          <div className="form-group">
                            <label>testo</label>
                            <textarea
                              name="sms_text"
                              value={texts[current_tab].sms_text}
                              onChange={this.onChange}
                              className="form-control"
                            />
                          </div>
                        </fieldset>
                        <hr />
                        <fieldset>
                          <legend>Email</legend>
                          <div className="form-group">
                            <label>Oggetto</label>
                            <input
                              name="email_subject"
                              maxLength={240}
                              value={texts[current_tab].email_subject}
                              onChange={this.onChange}
                              className="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label>Testo</label>
                            <textarea
                              name="email_text"
                              value={texts[current_tab].email_text}
                              onChange={this.onChange}
                              className="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label>HTML</label>
                            <textarea
                              name="email_html"
                              value={texts[current_tab].email_html}
                              onChange={this.onChange}
                              className="form-control"
                            />
                          </div>
                        </fieldset>
                        <hr />
                        <fieldset>
                          <legend>Whatsapp</legend>
                          <div className="form-group">
                            <label>Testo</label>
                            <textarea
                              name="whatsapp_text"
                              value={texts[current_tab].whatsapp_text}
                              onChange={this.onChange}
                              className="form-control"
                            />
                          </div>
                        </fieldset>
                        <button type="submit" className="btn btn-primary">
                          SALVA
                        </button>
                      </form>
                    </div>
                  </div>
                )}
                {add_lang && (
                  <div>
                    <select className="form-control" value={lang} onChange={this.onAddTexts}>
                      <option />
                      {langs
                        .filter(lang => !texts_langs.includes(lang))
                        .map(lang => {
                          return (
                            <option key={lang} value={lang}>
                              {lang}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col col-lg-4">
          <div className="card border-primary">
            <div className="card-header bg-primary text-white">
              <strong>Placeholders</strong>
            </div>
            <div className="card-body">
              <div className="list-group">
                {PLACE_HOLDERS.map((ph, i) => {
                  if (ph.enabled) {
                    if (!ph.enabled(this.props.template)) {
                      return null;
                    }
                  }
                  return (
                    <button
                      type="button"
                      className={`list-group-item list-group-item-action ${
                        current_placeholder === ph.id ? 'active' : ''
                      }`}
                      onClick={e => this.addPlaceholder(e, ph.id, ph.is_html)}
                    >
                      {ph.desc}
                    </button>
                  );
                })}
              </div>
              {current_placeholder && <div className="alert alert-info mt-3">{current_placeholder_text}</div>}
              <div className="text-muted mt-3">
                E' possibile utilizzare placeholder custom.
                <br />
                Il valore deve essere inviato nel payload con una chiave che inizia con <code>ph_</code>.<br />
                Ad esempio: <code>ph_numero_mittente='34512345678'</code> può essere utilizzato nel testo così:
                <br />
                <code>
                  Può contattarci al numero {'{{ '}numero_mittente{' }}'}
                </code>
                <br />
                Se il contenuto della variabile deve essere letterale, usare{' '}
                <code>
                  {'{{{'} {'}}}'}
                </code>
                <br />
                Ad esempio: <code>ph_some_url=http://www.example.com/path/one</code> deve essere utilizzato così:
                <br />
                <code>
                  Apra il seguente link {'{{{'} some_url {'}}}'}
                </code>
                <br />
                Maggiori dettagli sull'uso dei placeholder sono disponibili su{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/janl/mustache.js#variables">
                  github
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    Promise.all([Template.getTexts(this.props.template.code), loadLangs()])
      .then(([texts, langs]) => {
        const texts_langs = Object.keys(texts);
        const nextState = {
          langs,
          texts
        };
        if (texts_langs.length > 0) {
          nextState.current_tab = texts_langs[0];
        }
        this.setState(nextState);
      })
      .catch(e => this.setState({ error: e.message }));
  }

  addLang = () => {
    this.setState({ add_lang: true });
  };

  onAddTexts = e => {
    const lang = e.target.value;
    Template.addTexts(this.props.template.code, lang)
      .then(() => {
        this.setState({ add_lang: false }, () => {
          Template.getTexts(this.props.template.code).then(texts => {
            const texts_langs = Object.keys(texts);
            const nextState = {
              texts,
              current_tab: texts_langs[0]
            };
            this.setState(nextState);
          });
        });
      })
      .catch(e => this.setState({ error: e.message }));
  };

  onChange = e => {
    const value = e.target.value;
    const field = e.target.name;
    const { current_tab } = this.state;
    let newTexts = { ...this.state.texts };
    newTexts[current_tab][field] = value;
    this.setState({ texts: newTexts });
  };

  onSubmit = e => {
    e.preventDefault();
    const { texts, current_tab } = this.state;
    Template.updateTexts(this.props.template.code, current_tab, texts[current_tab])
      .then(() => {
        ltToast('Salvataggio effettuato');
      })
      .catch(e => this.setState({ error: e.message }));
  };

  addPlaceholder = (e, id, html) => {
    e.target.blur();
    if (this.state.current_placeholder === id) {
      this.setState({ current_placeholder: false, current_placeholder_text: false });
    } else {
      this.setState({ current_placeholder: id, current_placeholder_text: html ? `{{{ ${id} }}}` : `{{ ${id} }}` });
    }
  };
}

export default TemplateTexts;

import { deleteData, getData, postData, putData } from '../utils/httputils';

class Template {
  static apiPath = '/api/v1/templates';
  static get(code) {
    return getData(`${this.apiPath}/${code}`);
  }

  static getLatest() {
    return getData(`${this.apiPath}/latest`);
  }

  static create(data) {
    return postData(this.apiPath, data);
  }

  static update(code, field, value) {
    return putData(`${this.apiPath}/${code}`, { field, value });
  }

  static delete(code) {
    return deleteData(`${this.apiPath}/${code}`);
  }

  static find(params = {}) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Template.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'code'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static getTexts(code) {
    return getData(`${this.apiPath}/${code}/texts`);
  }

  static addTexts(code, lang) {
    return postData(`${this.apiPath}/${code}/texts`, { lang });
  }

  static updateTexts(code, lang, data) {
    return putData(`${this.apiPath}/${code}/texts/${lang}`, data);
  }
}

export default Template;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import TemplateMain from './TemplateMain';
import TemplateAdd from './TemplateAdd';
import TemplateView from './TemplateView';

class TemplateRoot extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired
  };

  render() {
    const { match, account } = this.props;
    const templateEnable = account.data?.template_enabled;

    if (!templateEnable) {
      return <div>Page not found</div>;
    }
    return (
      <div className="container bg-white mt-3 py-4">
        <Switch>
          <Route exact path={match.path} component={TemplateMain} />
          <Route exact path={`${match.path}/add`} component={TemplateAdd} />
          <Route exact path={`${match.path}/:code`} component={TemplateView} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(TemplateRoot);

import Account from './account';

export const REQUEST_USER_ME = 'REQUEST_USER_ME';
export const RECEIVE_USER_ME = 'RECEIVE_USER_ME';
export const FAILED_USER_ME = 'FAILED_USER_ME';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const RESET_LOGIN = 'RESET_LOGIN';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';

export function doGetUserMe() {
  return dispatch => {
    dispatch(requestUserMe());
    return Account.getMe()
      .then(json => {
        dispatch(reciveUserMe(json));
      })
      .catch(() => {
        dispatch(resetLogin());
      });
  };
}

export function doLogout() {
  // clearSessionToken();
  return async dispatch => {
    dispatch(requestLogout());
    try {
      await Account.doLogout();
      dispatch(receiveLogout());
    } catch (err) {
      console.error('Uops...', err);
      dispatch(receiveLogout());
    }
  };
}

export function requestLogout() {
  return {
    type: REQUEST_LOGOUT
  };
}

export function receiveLogout() {
  return {
    type: RECEIVE_LOGOUT
  };
}

export function resetLogin() {
  return {
    type: RESET_LOGIN
  };
}

function requestUserMe() {
  return {
    type: REQUEST_USER_ME
  };
}

function reciveUserMe(json) {
  return {
    type: RECEIVE_USER_ME,
    data: json
  };
}

export function requestLogin() {
  return {
    type: REQUEST_LOGIN
  };
}

export function receiveLogin(json) {
  return {
    type: RECEIVE_LOGIN,
    token: json
  };
}

import { deleteData, getData, postData, putData } from '../../utils/httputils';

class AdminEntity {
  static get(id) {
    return getData(`${this.apiPath}/${id}`);
  }

  static getLatest() {
    return getData(`${this.apiPath}/latest`);
  }

  static create(data) {
    return postData(this.apiPath, data);
  }

  static update(id, field, value) {
    return putData(`${this.apiPath}/${id}`, { field, value });
  }

  static delete(id) {
    return deleteData(`${this.apiPath}/${id}`);
  }

  static find(params = {}) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${this.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }
}

export default AdminEntity;

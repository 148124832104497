import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PersonIcon, SignOutIcon } from '@primer/octicons-react';
import { doLogout } from '../../lib/models/account';
import NotifHubLogo from '../../assets/NotifHubLogo';

class TopMenu extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    dropSettings: false,
    navBarCollapsed: true
  };

  render() {
    const {
      account: { data }
    } = this.props;
    if (!data) {
      return <div />;
    }
    const { dropSettings, navBarCollapsed } = this.state;

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <Link to="/" className="navbar-brand">
          <NotifHubLogo dark small />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarNav"
          aria-expanded={!navBarCollapsed}
          aria-label="Toggle navigation"
          onClick={() => this.setState({ navBarCollapsed: !navBarCollapsed })}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className={`${navBarCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
          <ul className="navbar-nav mr-auto">
            {this.getMenuItem('/reports', 'Reports')}
            {data.template_enabled && this.getMenuItem('/templates', 'Gestione template')}
            {(data.master || data.admin) && this.getMenuItem('/admin', 'Amministrazione')}
          </ul>
          <ul className="navbar-nav">
            {this.getMenuItem('/profile', 'Impostazioni', PersonIcon)}
            <li className="nav-item" title="Esci">
              <Link className="nav-link" to="/logout" onClick={this.doLogout}>
                <SignOutIcon className="d-none d-sm-block" size="medium" />
                <span className="d-block d-sm-none">Esci</span>
              </Link>
            </li>
          </ul>
        </div>
        {dropSettings && (
          <div
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999 }}
            onClick={e =>
              this.setState({
                dropSettings: false
              })
            }
          />
        )}
      </nav>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { account: oldUser } = prevProps;
    const { account: nextUser, history } = this.props;
    if (oldUser.data && !nextUser.data) {
      history.push('/');
    }
  }

  getMenuItem(link, desc, Icon, onClick) {
    let cls;
    if (link === '/') {
      cls = this.props.location.pathname === link ? 'active' : undefined;
    } else {
      cls = this.props.location.pathname.indexOf(link) === 0 ? 'active' : undefined;
    }

    return (
      <li className={'nav-item ' + cls} title={desc}>
        <Link
          to={link}
          className="nav-link"
          onClick={e => {
            if (onClick) {
              onClick(e);
            }
          }}
        >
          {Icon && <Icon className="d-none d-sm-block" size="medium" />}
          {Icon && <span className="d-block d-sm-none">{desc}</span>}
          {!Icon && desc}
        </Link>
      </li>
    );
  }

  doLogout = event => {
    event.preventDefault();
    this.setState({ dropSettings: false }, () => {
      this.props.dispatch(doLogout());
    });
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default withRouter(connect(mapStateToProps)(TopMenu));

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Use `LTSwitch` to list and edit options.
 */

class LTSwitch extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.bool,
    displayValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string
  };

  render() {
    const { name, value, onChange, id, displayValue } = this.props;
    let cid = id || `lt-switch-${name}`;

    return (
      <div className="custom-control custom-switch">
        <input
          checked={value}
          type="checkbox"
          className="custom-control-input"
          name={name}
          onChange={onChange}
          id={cid}
        />
        <label className="custom-control-label" htmlFor={cid}>
          {displayValue}
        </label>
      </div>
    );
  }
}

export default LTSwitch;

import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserHome from './home';

class Profile extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="container bg-white mt-3 py-3">
        <section className="user-content">
          <Route exact path={match.url} component={UserHome} />
        </section>
      </div>
    );
  }
}

export default Profile;

import React from 'react';
import LTFormElement from './LTFormElement';
import PropTypes from 'prop-types';

export default function LTFormDropDown(props) {
  return (
    <>
      <LTFormElement type={'drop-down'} {...props} />
    </>
  );
}

LTFormDropDown.prototype = {
  canEdit: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  onEnter: PropTypes.func,
  onEscape: PropTypes.func,
  options: PropTypes.object.isRequired,
  displayValue: PropTypes.string,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  onBlur: PropTypes.func
};

import React from 'react';
import LTFormElement from './LTFormElement';
import PropTypes from 'prop-types';

export default function LTFormInput(props) {
  return (
    <>
      <LTFormElement type={props.type ? props.type : 'text'} {...props} />
    </>
  );
}

LTFormInput.prototype = {
  canEdit: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  onEnter: PropTypes.func,
  onEscape: PropTypes.func,
  displayValue: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func
};

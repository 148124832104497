import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import checkAccount from './AccountComponent';
import { setTitle } from '../lib/utils/windowutils';
import { Route, Switch } from 'react-router-dom';
import AdminComp from './Admin';
import ReportsComp from './Reports';
import Profile from './Profile';
import TemplateRoot from './Templates';

class MainComp extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Home');
  }

  render() {
    return (
      <Switch>
        <Route path="/admin" component={AdminComp} />
        <Route path="/reports" component={ReportsComp} />
        <Route path="/templates" component={TemplateRoot} />
        <Route path="/profile" component={Profile} />
        <Route path="/" />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

const Main = connect(mapStateToProps)(checkAccount(MainComp));
export default Main;

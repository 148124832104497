import { getData } from '../utils/httputils';

const apiPath = '/api/v1/langs';

let langs;

export const loadLangs = async () => {
  if (!langs) {
    langs = await getData(apiPath);
  }
  return langs;
};

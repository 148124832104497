import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Account from '../../lib/models/account';
import NotifHubLogo from '../../assets/NotifHubLogo';

const Header = () => {
  return (
    <div className="header text-center py-4">
      <div className="text-primary">
        <NotifHubLogo />
      </div>
      <div className="mt-4 mb-5 p-2 bg-primary text-white">
        <h3>Recupera la tua password</h3>
      </div>
    </div>
  );
};

class LostPassword extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    email: '',
    message: '',
    done: false,
    running: false
  };

  emailInput = React.createRef();

  render() {
    const {
      account: { data }
    } = this.props;
    if (data) {
      return <div />;
    }
    if (this.state.done) {
      return (
        <div className="login-wrapper">
          <div className="">
            <Header />
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                  <div className="well well-lg test-center mt-3">
                    <div className="alert alert-primary mb-3">
                      Se il tuo indirizzo email è presente nel nostro database, ti abbiamo inviato una mail con il link
                      per impostare una nuova password.
                    </div>
                    <div className="text-muted">Se non ricevi la mail controlla nella cartella Spam</div>
                    <div className="mt-3 text-center">
                      <Link to="/" className="btn btn-primary w-100">
                        CONTINUE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="login-wrapper">
        <div className="">
          <Header />
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">Indirizzo email</label>
                    <input
                      ref={this.emailInput}
                      type="email"
                      className="form-control"
                      id="inputEmail"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      required
                      autoFocus
                      autoComplete="off"
                    />
                  </div>
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      disabled={!this.state.email || this.state.running}
                      className="btn btn-primary w-100"
                    >
                      {this.state.running ? (
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        'Recupera la password'
                      )}
                    </button>
                  </div>
                </form>
                <p className="mt-4">
                  <Link to="/login">Vuoi effettuare il login?</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.account.data) {
      this.props.history.replace('/');
      return;
    }
    if (this.emailInput.current) {
      this.emailInput.current.focus();
    }
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (!this.state.email) {
      return;
    }
    this.setState(
      {
        running: true
      },
      () => {
        Account.doLostPassword(this.state.email)
          .then(() => {
            this.setState({ done: true, running: false });
          })
          .catch(err => {
            console.log('what?', err);
            this.setState({ email: '', error: err.message, running: false }, () => {
              setTimeout(() => {
                this.setState({ error: '' });
              }, 1300);
            });
          });
      }
    );
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(LostPassword);

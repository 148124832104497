import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Use `LTDropDown` to list and edit options.
 */

class LTDropDown extends PureComponent {
  static propTypes = {
    isFocused: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    handleBlur: PropTypes.func,
    handleKeyDown: PropTypes.func,
    options: PropTypes.object.isRequired,
    multiple: PropTypes.bool
  };

  componentDidMount() {
    this.props.isFocused && this.refSelect && this.refSelect.current.focus();
  }

  refSelect = React.createRef();

  render() {
    const { name, value, onChange, id, handleKeyDown, handleBlur, options, multiple } = this.props;
    return (
      <>
        <select
          multiple={multiple}
          className="form-control form-control-sm"
          ref={this.refSelect}
          name={name}
          id={id}
          onChange={onChange}
          value={value}
          onKeyDown={e => handleKeyDown(e)}
          onBlur={() => handleBlur()}
        >
          {!value && <option value=""></option>}
          {Object.keys(options).map((key, i) => {
            const value = options[key];
            return (
              <option key={i} value={key}>
                {value}
              </option>
            );
          })}
        </select>
      </>
    );
  }
}

export default LTDropDown;

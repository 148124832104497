import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import { Link } from 'react-router-dom';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import Template from '../../lib/models/Template';

class TemplateAdd extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    error: false,
    code: '',
    description: '',
    use_attachment: false,
    enabled: true
  };

  refInput = React.createRef();

  render() {
    const { code, description, use_attachment, enabled, error } = this.state;

    const validated = code && description;

    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/templates">Templates</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nuovo
            </li>
          </ol>
        </nav>
        {error && <div className="alert alert-danger">{error}</div>}
        <div>
          <div className="row">
            <div className="mb-3 col col-md-10 col-lg-8">
              <div className="card border-primary">
                <div className="card-header bg-primary text-white">
                  <strong>Nuovo Template</strong>
                </div>
                <div className="card-body">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                      <label htmlFor="inputcode" className="col-sm-2 col-form-label">
                        Codice
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="code"
                          value={code}
                          className="form-control"
                          id="inputcode"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputDescription" className="col-sm-2 col-form-label">
                        Descrizione
                      </label>
                      <div className="col-sm-10">
                        <input
                          name="description"
                          type="text"
                          value={description}
                          className="form-control"
                          id="inputDescription"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputUseAttachment" className="col-sm-2 col-form-label">
                        Allegati abilitati
                      </label>
                      <div className="col-sm-10 pt-2">
                        <div className="custom-control custom-switch">
                          <input
                            checked={use_attachment}
                            type="checkbox"
                            className="custom-control-input"
                            name="use_attachment"
                            id="inputUseAttachment"
                            onChange={this.handleChange}
                          />
                          <label className="custom-control-label" htmlFor="status">
                            {enabled ? 'Abilitati' : 'Disabilitati'}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputEnabled" className="col-sm-2 col-form-label">
                        Abilitato
                      </label>
                      <div className="col-sm-10 pt-2">
                        <div className="custom-control custom-switch">
                          <input
                            checked={enabled}
                            type="checkbox"
                            className="custom-control-input"
                            name="enabled"
                            id="inputEnabled"
                            onChange={this.handleChange}
                          />
                          <label className="custom-control-label" htmlFor="status">
                            {enabled ? 'Abilitato' : 'Disabilitato'}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary" disabled={!validated}>
                          SALVA
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Nuovo template');
  }

  onSubmit = event => {
    event.preventDefault();
    const { code, description, use_attachment, enabled } = this.state;
    if (!code || !description) return;
    Template.create({ code, description, use_attachment, enabled })
      .then(data => {
        this.props.history.push(`./${data.code}`);
      })
      .catch(e => {
        ltToast('Fallita creazione template:' + e.message, false, true);
      });
  };

  handleChange = event => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'code') {
      value = value.toUpperCase();
    }
    this.setState({
      [name]: value
    });
  };
}

export default TemplateAdd;

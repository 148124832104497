import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import Accounts from '../../lib/models/admin/Account';
import { Link } from 'react-router-dom';
import { getRoles, getRolesAsString, getRoleString, loadRoles } from '../../lib/models/Roles';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import LTFormDropDown from '../Common/LT-Form-Elements/LTFormInputs/LTFormDropDown';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { connect } from 'react-redux';

class AccountView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired
  };

  state = {
    account: false,
    error: false
  };

  refInput = React.createRef();

  render() {
    const { account, error } = this.state;
    const user = this.props.account.data;
    const canEdit = user.role <= account.role;
    const roles =
      this.roles &&
      this.roles
        .filter(role => role.id >= user.role)
        .reduce((acc, role) => {
          acc[role.id] = getRoleString(role.name);
          return acc;
        }, {});
    let last_activity = '';
    if (account.last_activity_at) {
      const d_last_activity = new Date(account.last_activity_at);
      last_activity = `${d_last_activity.toLocaleDateString()} ${d_last_activity.toLocaleTimeString()}`;
    }
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Amministrazione</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/accounts">Utenti</Link>
            </li>
            {account && (
              <li className="breadcrumb-item active" aria-current="page">
                {account.email}
              </li>
            )}
          </ol>
        </nav>
        {error && <div className="alert alert-danger">{error}</div>}
        {!error && !account && <Loading />}
        {account && (
          <div>
            <h5 className="mb-3">Modifica Utente</h5>
            <div className="row">
              <div className="mb-3 col col-md-6">
                <div className="card border-primary">
                  <div className="card-header bg-primary text-white">
                    <strong>Dettaglio</strong>
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className="col col-md-4">Email</dt>
                      <dd className="col col-md-8">{account.email}</dd>
                      <dt className="col col-md-4">Name</dt>
                      <LTFormInput
                        className="col col-md-8"
                        canEdit={canEdit}
                        name={'name'}
                        value={account.name}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col col-md-4">Roles</dt>
                      <LTFormDropDown
                        className="col col-md-8"
                        name={'role'}
                        displayValue={getRolesAsString(account.role)}
                        value={account.role}
                        canEdit={canEdit}
                        options={roles}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col col-md-4">Stato</dt>
                      <LTFormDropDown
                        canEdit={canEdit}
                        name="status"
                        className="col col-md-8"
                        displayValue={
                          account.status === 1 ? 'Attivo' : account.status === 9 ? 'Disattivato' : 'Inattivo'
                        }
                        value={account.status}
                        options={{ 1: 'Attivo', 9: 'Disattivato' }}
                        onChange={this.handleChange}
                        onEscape={this.undoChanges}
                        onBlur={this.saveEdit}
                      />
                      <dt className="col col-md-4">Ultimo accesso</dt>
                      <dd className="col col-md-8">{last_activity}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            {this.props.account.data.role === 1 && (
              <div className="mt-3">
                <button className="btn btn-danger" onClick={this.deleteAccount}>
                  CANCELLA
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    loadRoles().then(roles => {
      this.roles = roles;
      this.loadAccount();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.account && this.state.account.id && !prevState.account.id) {
      setTitle(this.state.account.email);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadAccount();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleChange = e => {
    e.preventDefault();
    let newAccount = { ...this.state.account };
    newAccount[e.target.name] = e.target.value;
    this.setState({ account: newAccount });
  };

  saveEdit = (field, value) => {
    const { oldAccount, account } = this.state;
    if (oldAccount[field] !== value) {
      Accounts.update(account.id, field, value)
        .then(account => {
          this.setState({ account, oldAccount: account });
        })
        .catch(e => {
          ltToast('Fallito salvataggio modifica:' + e.message, false, true);
        });
    }
  };

  undoChanges = (field, value) => {
    const { oldAccount } = this.state;
    if (oldAccount[field] !== value) {
      let resetedAccount = { ...this.state.oldAccount };
      this.setState({
        account: resetedAccount
      });
    }
  };

  deleteAccount = () => {
    Accounts.delete(this.state.account.id).then(() => {
      this.props.history.replace('/admin/accounts');
    });
  };
  loadAccount = () => {
    const { match } = this.props;
    const { params } = match;
    Accounts.get(params.id)
      .then(account => {
        this.setState({
          account,
          oldAccount: account,
          editedRoles: getRoles(account.role).map(role => role.id)
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(AccountView);

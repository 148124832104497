import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import checkUser from '../AccountComponent';
import ChangePasswordForm from '../Common/ChangePasswordForm';

class UserHome extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired
  };

  state = {
    error: false,
    done: false
  };

  render() {
    const { error, done } = this.state;
    const { account } = this.props;
    return (
      <div className="mt-3">
        <div className="row mb-3">
          <div className="col">
            <div className="mb-3">
              <h2>
                {account.data.name} <small>({account.data.email})</small>
              </h2>
            </div>
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                <strong>Cambia Password</strong>
              </div>
              <div className="card-body">
                <ChangePasswordForm
                  check_old_password={true}
                  hideTitle={true}
                  onPasswordChanged={() => {
                    this.setState({ done: true, error: false }, () => {
                      setTimeout(() => {
                        this.setState({ done: false });
                      }, 5000);
                    });
                  }}
                  onPasswordChangeFailed={error =>
                    this.setState({ error, done: false }, () => {
                      setTimeout(() => {
                        this.setState({ error: false });
                      }, 5000);
                    })
                  }
                />
              </div>
            </div>
            {error && <div className="alert mt-3 alert-warning">{error}</div>}
            {done && <div className="alert mt-3 alert-info">Password cambiata con successo</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default checkUser(UserHome);

import { combineReducers } from 'redux';
import { accountReducer } from './lib/models/account/';

import { connectRouter } from 'connected-react-router';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    account: accountReducer
  });

export default rootReducer;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store';
import TopMenu from './components/TopMenu';
import Login from './components/Login';
import Main from './components/Main';
import { doGetUserMe } from './lib/models/account';
import LostPassword from './components/LostPassword';
import Confirm from './components/Confirm';

class BaseAppComp extends PureComponent {
  static propTypes = {
    account: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired
  };

  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div className="app-wrapper">
            <Router>
              <div className="root-wrapper">
                <div className="header-wrapper">
                  <TopMenu />
                </div>
                <div className="main-wrapper">
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/lost-password" component={LostPassword} />
                    <Route exact path="/reset/:token" component={Confirm} />
                    <Route path="/" component={Main} />
                  </Switch>
                </div>
              </div>
            </Router>
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }

  componentDidMount() {
    this.props.dispatch(doGetUserMe());
  }
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

const App = connect(mapStateToProps)(BaseAppComp);
export default App;

import React, { PureComponent } from 'react';
import { PencilIcon } from '@primer/octicons-react';
import PropTypes from 'prop-types';

/**
 * Use `DescriptionEdit` as a placehodler for lt-form-inputs.
 */

export default class DescriptionEdit extends PureComponent {
  static propTypes = {
    canEdit: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
  };
  state = {
    showIcon: false
  };

  render() {
    if (this.props.canEdit !== true) {
      return <div>{this.props.children}</div>;
    }
    return (
      <div
        style={{ cursor: 'pointer' }}
        onMouseOver={e => this.setState({ showIcon: true })}
        onMouseLeave={e => this.setState({ showIcon: false })}
      >
        {this.props.children}
        <div className={(this.state.showIcon ? '' : 'invisible ') + 'float-right'}>
          <div className="text-muted" title="Edit">
            <PencilIcon size="small" />
          </div>
        </div>
      </div>
    );
  }
}

import React, { PureComponent } from 'react';
import { setTitle } from '../../lib/utils/windowutils';
import Account from '../../lib/models/admin/Account';
import PropTypes from 'prop-types';
import qs from 'qs';
import { PlusIcon, SearchIcon, TrashcanIcon } from '@primer/octicons-react';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import { Link } from 'react-router-dom';
import { getRolesAsString } from '../../lib/models/Roles';

class AccountComp extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    accounts: { rows: [] },
    searchString: ''
  };

  render() {
    const { match, history } = this.props;
    const { accounts, searchString } = this.state;
    const { offset } = this.getQueryParameters();

    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Amministrazione</Link>
            </li>
            <li className="breadcrumb-item active">Utenti</li>
          </ol>
        </nav>
        <div className="my-3 row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" onClick={this.handleSearch}>
                  <SearchIcon size="small" />
                </span>
              </div>
              <input
                type="text"
                value={searchString}
                onChange={e => this.setState({ searchString: e.target.value })}
                onKeyDown={e => handleOnKeyDown({ event: e, onEnter: this.handleSearch })}
                className="form-control form-control-sm"
                placeholder="Search"
              />
              <div className="input-group-append">
                <button
                  disabled={searchString === ''}
                  className="input-group-text text-danger"
                  onClick={e => history.push(match.url)}
                >
                  <TrashcanIcon size="small" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          {accounts.rows.length > 0 && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Nome</th>
                  <th>Ruolo</th>
                  <th>Stato</th>
                  <th>Ultimo Accesso</th>
                </tr>
              </thead>
              <tbody>
                {accounts.rows.map((account, i) => {
                  let last_activity = '';
                  if (account.last_activity_at) {
                    const d_last_activity = new Date(account.last_activity_at);
                    last_activity = `${d_last_activity.toLocaleDateString()} ${d_last_activity.toLocaleTimeString()}`;
                  }
                  return (
                    <tr key={i} className="justify-content-between align-items-center">
                      <td>
                        <Link to={`${match.path}/${account.id}`}>{account.email}</Link>
                      </td>
                      <td>{account.name}</td>
                      <td>{getRolesAsString(account.role)}</td>
                      <td>{account.status === 1 ? 'Attivo' : account.status === 9 ? 'Disattivato' : 'Inattivo'}</td>
                      <td>{last_activity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {accounts.rows.length === 0 && <div className="alert alert-info">Nessun account trovato</div>}
        </div>
        <div className="d-flex bd-highlight mb-3">
          <div className="mt-3" title="Add Account">
            <Link to={`${match.url}/add`} className="btn btn-primary">
              <PlusIcon size="small" />
            </Link>
          </div>
          {(Number(offset) || accounts.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(this.getQueryParameters().offset) ? ' disabled' : '')}>
                    <Link to={this.getPaginationUrl('p')} className="page-link" onClick={e => e.target.blur()}>
                      Previous
                    </Link>
                  </li>
                  <li className={'page-item' + (accounts.hasMore === false ? ' disabled' : '')}>
                    <Link to={this.getPaginationUrl('n')} className="page-link" onClick={e => e.target.blur()}>
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Utenti');
    this.loadAccounts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadAccounts();
    }
  }

  loadAccounts = () => {
    const { search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    const { limit, offset, order_by, sort, q } = parameters;

    let searchString = '';
    if (q) {
      searchString = q;
    }
    if (this.state.searchString !== searchString) {
      this.setState({ searchString });
    }

    Account.find({ limit, offset, order_by, sort, search: q }).then(accounts => {
      this.setState({ accounts });
    });
  };

  handleSearch = () => {
    const { pathname, search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    parameters.q = this.state.searchString;
    parameters.offset = 0;
    this.props.history.push(pathname + qs.stringify(parameters, { addQueryPrefix: true }));
  };

  getQueryParameters = () => {
    const { search } = this.props.location;
    return qs.parse(search, { ignoreQueryPrefix: true });
  };

  getPaginationUrl = direction => {
    const { pathname } = this.props.location;
    const parameters = this.getQueryParameters();
    let offset = Number(parameters.offset) || 0;
    let limit = Number(parameters.limit) || 10;
    let offsetPrev = offset - limit;
    let offsetNext = offset + limit;
    if (offsetPrev < 0) {
      offsetPrev = 0;
    }
    parameters.offset = direction === 'p' ? offsetPrev : offsetNext;
    return pathname + qs.stringify(parameters, { addQueryPrefix: true });
  };
}

export default AccountComp;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { loadChannels } from '../../lib/models/Channel';
import { loadReport } from '../../lib/models/Report';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import it from 'date-fns/locale/it';
import { Link } from 'react-router-dom';
import qs from 'qs';
registerLocale('it', it);

class ReportsComp extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    channels: [],
    report: []
  };

  render() {
    const { channels, report } = this.state;
    const { machine, from, to } = this.getQueryParameters();

    return (
      <div className="container bg-white mt-3 py-4">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Reports</li>
          </ol>
        </nav>
        <div className="row">
          <div className="col">
            <div className="p-4">
              <div className="mb-3">
                <form className="form-inline" onSubmit={this.onSearch}>
                  <label htmlFor="dateFrom" className="mr-3">
                    Da
                  </label>
                  <DatePicker
                    locale="it"
                    dateFormat="dd/MM/yyyy"
                    className="form-control mb-2 mr-sm-2"
                    selected={from}
                    onChange={from => this.updateQueryParameters({ from })}
                  />
                  <label htmlFor="dateTo" className="mr-3">
                    A
                  </label>
                  <DatePicker
                    locale="it"
                    dateFormat="dd/MM/yyyy"
                    className="form-control mb-2 mr-sm-2"
                    selected={to}
                    onChange={to => this.updateQueryParameters({ to })}
                  />
                  <label htmlFor="machine" className="mr-3">
                    Sorgente
                  </label>
                  <input
                    id="machine"
                    type="text"
                    className="form-control mb-2 mr-sm-2"
                    onChange={e => this.updateQueryParameters({ machine: e.target.value })}
                    value={machine}
                  />
                  <button className="btn btn-primary mb-2 mr-sm-2" type="submit">
                    ESTRAI
                  </button>
                </form>
              </div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>sorgente</th>
                    {channels.map((channel, i) => {
                      return <th key={i}>{channel}</th>;
                    })}
                    <th>totale</th>
                  </tr>
                </thead>
                <tbody>
                  {report.length === 0 && (
                    <tr>
                      <td colSpan={2 + channels.length}>Nessun record trovato</td>
                    </tr>
                  )}
                  {report.length > 0 &&
                    report.map((row, i) => {
                      return (
                        <tr key={i}>
                          <th>{row.machine}</th>
                          {channels.map((channel, ii) => {
                            return <td key={ii}>{row[channel]}</td>;
                          })}
                          <th>{row.total}</th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    loadChannels().then(channels => this.setState({ channels }, this.onSearch));
  }

  onSearch = e => {
    e && e.preventDefault();
    const { machine, from, to } = this.getQueryParameters();
    const d_from = from.getTime();
    const d_to = to.getTime();
    loadReport(d_from > d_to ? d_to : d_from, d_from > d_to ? d_from : d_to, machine)
      .then(report => {
        this.setState({ report });
      })
      .catch(e => this.setState({ error: e.message }));
  };

  getQueryParameters = () => {
    const { search } = this.props.location;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    let { machine, from, to } = params;
    if (!machine) {
      machine = '';
    }
    if (!from) {
      from = new Date();
    } else {
      from = new Date(from);
    }
    if (!to) {
      to = new Date();
    } else {
      to = new Date(to);
    }
    return { machine, from, to };
  };

  updateQueryParameters = data => {
    const { pathname, search } = this.props.location;
    const { machine, from, to } = data;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    if (machine || machine === '') {
      parameters.machine = machine;
    }
    if (from) {
      parameters.from = from.toISOString();
    }
    if (to) {
      parameters.to = to.toISOString();
    }
    this.props.history.push(pathname + qs.stringify(parameters, { addQueryPrefix: true }));
  };
}

export default ReportsComp;

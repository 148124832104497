import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Loading from '../Common/Loading';
import Template from '../../lib/models/Template';
import { setTitle } from '../../lib/utils/windowutils';
import LTFormInput from '../Common/LT-Form-Elements/LTFormInputs/LTFormInput';
import LTFormDropDown from '../Common/LT-Form-Elements/LTFormInputs/LTFormDropDown';
import TemplateTexts from './TemplateTexts';

class TemplateView extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    error: false,
    template: false
  };
  render() {
    const { error, template } = this.state;
    if (!template) {
      return <Loading />;
    }
    const canEdit = true;
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/templates">Templates</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {template.description}
            </li>
          </ol>
        </nav>
        {error && <div className="alert alert-danger">{error}</div>}
        <div>
          <div className="row">
            <div className="mb-3 col">
              <div className="card border-primary">
                <div className="card-header bg-primary text-white">
                  <strong>Template</strong>
                </div>
                <div className="card-body">
                  <dl className="row">
                    <dt className="col col-md-4">Codice</dt>
                    <dd className="col col-md-8">{template.code}</dd>
                    <dt className="col col-md-4">Descrizione</dt>
                    <LTFormInput
                      className="col col-md-8"
                      canEdit={canEdit}
                      name={'description'}
                      value={template.description}
                      onChange={this.handleChange}
                      onEscape={this.undoChanges}
                      onBlur={this.saveEdit}
                    />
                    <dt className="col col-md-4">Allegati</dt>
                    <LTFormDropDown
                      canEdit={canEdit}
                      name="use_attachment"
                      className="col col-md-8"
                      displayValue={template.use_attachment ? 'Abiliatati' : 'Disabilitati'}
                      value={template.use_attachment}
                      options={{ 0: 'Disabilitati', 1: 'Abiliatati' }}
                      onChange={this.handleChange}
                      onEscape={this.undoChanges}
                      onBlur={this.saveEdit}
                    />
                    <dt className="col col-md-4">Stato</dt>
                    <LTFormDropDown
                      canEdit={canEdit}
                      name="enabled"
                      className="col col-md-8"
                      displayValue={template.enabled ? 'Abiliatato' : 'Disabilitato'}
                      value={template.enabled}
                      options={{ 0: 'Disabilitato', 1: 'Abiliatato' }}
                      onChange={this.handleChange}
                      onEscape={this.undoChanges}
                      onBlur={this.saveEdit}
                    />
                  </dl>
                  <button className="btn btn-danger" onClick={this.deleteTemplate}>
                    CANCELLA
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TemplateTexts template={template} />
      </div>
    );
  }

  componentDidMount() {
    this.loadTemplate();
  }

  loadTemplate = () => {
    const { match } = this.props;
    const { params } = match;
    Template.get(params.code)
      .then(template => {
        setTitle(template.description);
        this.setState({ template, oldTemplate: template });
      })
      .catch(e => this.setState({ error: e.message }));
  };

  deleteTemplate = () => {
    const proceed = window.confirm('Sicuro?');
    if (!proceed) return;
    Template.delete(this.state.template.code)
      .then(() => {
        this.props.history.replace('/templates');
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };

  handleChange = e => {
    e.preventDefault();
    let newAccount = { ...this.state.template };
    newAccount[e.target.name] = e.target.value;
    this.setState({ template: newAccount });
  };

  saveEdit = (field, value) => {
    const { oldTemplate, template } = this.state;
    if (oldTemplate[field] !== value) {
      Template.update(template.code, field, value)
        .then(template => {
          this.setState({ template, oldTemplate: template });
        })
        .catch(e => {
          // restore...
          template[field] = oldTemplate[field];
          this.setState({ error: e.message, template });
        });
    }
  };

  undoChanges = (field, value) => {
    const { oldTemplate } = this.state;
    if (oldTemplate[field] !== value) {
      let resetedAccount = { ...this.state.oldTemplate };
      this.setState({
        template: resetedAccount
      });
    }
  };
}

export default TemplateView;

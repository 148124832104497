import React, { PureComponent } from 'react';

class NoAccountComponent extends PureComponent {
  render() {
    const { account, children } = this.props;
    if (account.data) {
      return <div>Already logged in</div>;
    }
    return children;
  }
  componentDidMount() {
    const { account, history } = this.props;
    if (account.data) {
      setTimeout(() => {
        history.push('/');
      }, 5000);
    }
  }
}

export default NoAccountComponent;

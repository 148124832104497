import {
  RECEIVE_USER_ME,
  REQUEST_USER_ME,
  FAILED_USER_ME,
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  RESET_LOGIN,
  REQUEST_LOGOUT,
  RECEIVE_LOGOUT
} from './accountActions';

const initialState = {
  isFetching: false,
  isLoaded: false,
  isLoggingIn: false,
  data: undefined
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_USER_ME:
      return Object.assign({}, state, { isFetching: true });

    case RECEIVE_USER_ME:
      return Object.assign({}, state, { isFetching: false, isLoaded: true, data: action.data });

    case FAILED_USER_ME:
      return Object.assign({}, state, { isFetching: false, isLoaded: true });

    case REQUEST_LOGIN:
      return Object.assign({}, state, { isLoggingIn: true });

    case RECEIVE_LOGIN:
      return Object.assign({}, state, { isLoggingIn: false });

    case RESET_LOGIN:
      return Object.assign({}, state, { isLoggingIn: false, isLoaded: true });

    case REQUEST_LOGOUT:
      const logginOutState = {
        isLoaded: false,
        isLoggingIn: false,
        data: undefined
      };
      return Object.assign({}, state, logginOutState);

    case RECEIVE_LOGOUT:
      const loggedOutState = {
        isLoaded: true,
        data: undefined
      };
      return Object.assign({}, state, loggedOutState);

    default:
      return state;
  }
}

import { getData } from '../utils/httputils';
import qs from 'qs';

const apiPath = '/api/v1/admin/report';

export const loadReport = async (from, to, machine) => {
  const parameters = {
    from,
    to,
    machine
  };
  const url = apiPath + qs.stringify(parameters, { addQueryPrefix: true });
  return getData(url);
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { setTitle } from '../../lib/utils/windowutils';
import Accounts from '../../lib/models/admin/Account';
import { Link } from 'react-router-dom';
import { loadRoles } from '../../lib/models/Roles';
import { ltToast } from '../Common/LT-Form-Elements/LTToast';
import { connect } from 'react-redux';

export const filterRoles = function(roles, account_role) {
  return roles.filter(r => r.id >= account_role);
};

class AccountAdd extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    roles: [],
    account: false,
    error: false,
    email: '',
    name: '',
    role: '',
    status: false
  };

  refInput = React.createRef();

  render() {
    const { email, name, status, role, roles, error } = this.state;

    if (!roles) return <Loading />;

    const validated = email && name && role;

    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Amministrazione</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/accounts">Utenti</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nuovo
            </li>
          </ol>
        </nav>
        {error && <div className="alert alert-danger">{error}</div>}
        <div>
          <div className="row">
            <div className="mb-3 col col-md-8 col-lg-6">
              <div className="card border-primary">
                <div className="card-header bg-primary text-white">
                  <strong>Crea Account</strong>
                </div>
                <div className="card-body">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                      <label htmlFor="inputEmail" className="col-sm-2 col-form-label">
                        Email
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="email"
                          name="email"
                          value={email}
                          className="form-control"
                          id="inputEmail"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputName" className="col-sm-2 col-form-label">
                        Nome
                      </label>
                      <div className="col-sm-10">
                        <input
                          name="name"
                          type="text"
                          value={name}
                          className="form-control"
                          id="inputName"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputName" className="col-sm-2 col-form-label">
                        Ruolo
                      </label>
                      <div className="col-sm-10">
                        <select name="role" className="form-control" onChange={this.handleChange} value={role}>
                          <option />
                          {filterRoles(roles, this.props.account.data.role).map((role, i) => {
                            return (
                              <option key={i} value={role.id}>
                                {role.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="inputName" className="col-sm-2 col-form-label">
                        Stato
                      </label>
                      <div className="col-sm-10 pt-2">
                        <div className="custom-control custom-switch">
                          <input
                            checked={status}
                            type="checkbox"
                            className="custom-control-input"
                            name="status"
                            id="status"
                            onChange={this.handleChange}
                          />
                          <label className="custom-control-label" htmlFor="status">
                            {status ? 'Attivo' : 'Disattivo'}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary" disabled={!validated}>
                          SALVA
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Crea utente');
    loadRoles().then(roles => {
      this.setState({ roles });
    });
  }

  onSubmit = event => {
    event.preventDefault();
    const { email, name, role, status } = this.state;
    if (!email || !name || !role) return;
    Accounts.create({ email, name, role, status })
      .then(data => {
        this.props.history.push(`./${data.id}`);
      })
      .catch(e => {
        ltToast('Fallita creazione utente:' + e.message, false, true);
      });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account
  };
}

export default connect(mapStateToProps)(AccountAdd);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Use `LTTextArea` to display Text.
 */

class LTTextArea extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    handleBlur: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    isFocused: PropTypes.bool,
    rows: PropTypes.number
  };

  refInput = React.createRef();

  componentDidMount() {
    this.props.isFocused && this.refInput && this.refInput.current.focus();
  }
  render() {
    const { name, value, placeholder, onChange, id, handleKeyDown, handleBlur, rows } = this.props;

    return (
      <div>
        <textarea
          rows={rows}
          className={'form-control form-control-sm'}
          id={id}
          ref={this.refInput}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown && handleKeyDown}
          onBlur={handleBlur && handleBlur}
        />
        {handleBlur && (
          <button type="button" className="btn btn-sm btn-outline-light float-right mt-2" onClick={handleBlur}>
            Save
          </button>
        )}
      </div>
    );
  }
}

export default LTTextArea;
